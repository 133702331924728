import React, { useEffect, useState } from 'react';
import { navigate, Link, PageProps } from 'gatsby';

import { Translate } from 'src/components/Translate';
import { useAuthContext, useRequireAuthenticated } from 'src/components/AuthProvider';
import useActivateAutoRenew from 'src/api/useActivateAutoRenew';
import useMembership from 'src/api/useMembership';
import useDateFormat from 'src/hooks/useDateFormat';
import useRedirect from 'src/hooks/useRedirect';
import { SimpleFooter } from 'src/features/footer';

const ConfirmAutoRenew = ({ location }: PageProps) => {
  useRedirect('/my-account/');

  const { state = {} } = location;
  const { isAuthenticated } = useAuthContext();
  const [changeInProgress, setChangeInProgress] = useState<boolean>();
  const { data: membership, ...membershipQuery } = useMembership();
  const activateAutoRenew = useActivateAutoRenew(membership?.inventory?.id);
  const originUrl = (state && state['origin']) || '/my-account/';
  const format = useDateFormat();
  useRequireAuthenticated();

  const i18n = {
    confirmButton: 'autoRenew.confirmButton.text',
    cancelButton: 'autoRenew.cancelButton.text',
    leadText: 'autoRenew.lead.text',
    heading: 'autoRenew.heading',
    amountTitle: 'autoRenew.amount.full',
    confirm: 'autoRenew.confirm.text',
    changeInProgress: 'autoRenew.change.progress',
    bannerSuccess: 'autoRenew.banner.success',
  };

  useEffect(() => {
    // Once the updated membership is fetched, redirect and show success banner
    if (membership?.data?.subscription?.status === 'active') {
      membershipQuery.stopPolling();
      setChangeInProgress(false);
      navigate(originUrl, { state: { msg: i18n.bannerSuccess } });
    }
  }, [membership]);

  const handleOnClick = async () => {
    try {
      const { data, errors } = await activateAutoRenew();
      if (errors) {
        return console.log('[auto-renew] error: ', errors);
      }
      console.log('[auto-renew is active]', data);
      // Auto-renew was set, fetch updated membership
      membershipQuery.startPolling(200);
      setChangeInProgress(true);
    } catch (e) {
      console.log('[auto-renew] error: ', e);
    }
  };

  const shouldRedirect = !membershipQuery.loading && membership && !membership.hasMembership();
  useEffect(() => {
    if (shouldRedirect) {
      console.log('Navigate to my-account because the user does not has membership');
      navigate('/my-account/');
    }
  }, [shouldRedirect]);

  return (
    <>
      {isAuthenticated && membership?.hasMembership() && (
        <div className="container container_center page__update_membership">
          <h2 className="text-display_2">
            <Translate resourceKey={i18n.heading} />
          </h2>
          <div className="inset-s" />
          <div className="text_2">
            <Translate
              resourceKey={i18n.leadText}
              values={{
                gear: membership.data?.name,
                renewDate: format(membership.expireAt),
              }}
            />
          </div>
          <div className="inset-l" />
          <div className="text_2">
            <Translate
              resourceKey={i18n.amountTitle}
              values={{
                price: (membership.data?.price / 100).toFixed(2),
              }}
            />
          </div>
          <div className="inset-m" />
          <div className="text_2">
            <Translate resourceKey={i18n.confirm} />
          </div>
          <div className="inset-s" />

          <div className="cta_button-group button-group">
            <div className="button-group__item">
              <button
                onClick={handleOnClick}
                data-cy="confirm-auto-renew"
                className="button button_primary button_primary--large"
                disabled={changeInProgress}
              >
                {changeInProgress ? (
                  <Translate resourceKey={i18n.changeInProgress} />
                ) : (
                  <Translate resourceKey={i18n.confirmButton} />
                )}
              </button>
            </div>
            {!changeInProgress && (
              <div className="button-group__item">
                <Link to={originUrl} className="button button_secondary">
                  <Translate resourceKey={i18n.cancelButton} />
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="inset-l" />

      <SimpleFooter />
    </>
  );
};

export default ConfirmAutoRenew;
