import { useMutation } from '@apollo/client';
import UpdateSubscriptionMutation from './graphql/users/mutations/UpdateSubscriptionMutation.graphql';

const useActivateAutoRenew = (inventoryId) => {
  const [activateAutoRenew] = useMutation(UpdateSubscriptionMutation, {
    variables: {
      input: {
        context: {
          inventoryId: inventoryId,
        },
        status: 'active',
      },
    },
  });

  return activateAutoRenew;
};

export default useActivateAutoRenew;
